import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import ItemDocumento from '@/modules/documentos/components/itemDocumento/itemDocumento.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout,
    ItemDocumento,
  }
})
export default class Documento extends Vue {

}
  